import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js'
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'


import { MOUSE, TOUCH } from 'three'
import { temp } from 'three/examples/jsm/nodes/Nodes.js'

class MapControls extends OrbitControls {

	constructor( object, domElement ) {

		super( object, domElement );

		this.screenSpacePanning = false; // pan orthogonal to world-space direction camera.up

		this.mouseButtons = { LEFT: MOUSE.PAN, MIDDLE: MOUSE.DOLLY, RIGHT: MOUSE.ROTATE };

		this.touches = { ONE: TOUCH.PAN, TWO: TOUCH.DOLLY_ROTATE };

	}

}
export { MapControls }

// console.log(MapControls)

// console.log(PointerLockControls)

/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Fog
const fog = new THREE.Fog('#262837', 1, 15000)
scene.fog = fog  
gui.add(fog, 'far').min(500).max(15000).step(1).name('fog')

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

const bricksColorTexture = textureLoader.load('/textures/bricks/durbar.jpg')
const bricksAmbientOcclusionTexture = textureLoader.load('/textures/bricks/ambientOcclusion.jpg')
const bricksNormalTexture = textureLoader.load('/textures/bricks/normal.jpg')
const bricksRoughnessTexture = textureLoader.load('/textures/bricks/roughness.jpg')
const nayatpolTexture = textureLoader.load('/textures/nayatpola/nayatpola3.jpg')
const nayatpolUpTexture = textureLoader.load('/textures/nayatpola/nayatpolatop.jpg')
const nayatpolBaseTexture = textureLoader.load('/textures/nayatpola/base.jpg')
const templeTexture = textureLoader.load('/textures/bricks/temple.jpg')
const dharaharaTexture = textureLoader.load('/textures/dharahara/dharahara.jpg')
const skyTexture = textureLoader.load( "/textures/sky/sky7.jpg" )
const leavesTexture = textureLoader.load( "/textures/tree/leaves.jpg" )
const stemTexture = textureLoader.load( "/textures/tree/stem.jpg" )
const KaalBhairavTexture = textureLoader.load( "/textures/statue/kaalBhairav.jpg" )
const bouddhaDomeTexture = textureLoader.load( "/textures/bouddha/bouddhaDome2.jpg" )
const bouddhaEyeTexture = textureLoader.load( "/textures/bouddha/bouddhaEye.jpg" )
const bouddhaPyramidTexture = textureLoader.load( "/textures/bouddha/bouddhaPyramid.jpg" )

// // Temple Textures
// const templeA = textureLoader.load('/textures/temple/new/a.jpg')
// const templeB = textureLoader.load('/textures/temple/new/b.jpg')
// const templeC = textureLoader.load('/textures/temple/new/c.jpg')
// const templeD = textureLoader.load('/textures/temple/new/d.jpg')
// const templeE = textureLoader.load('/textures/temple/new/e.jpg')
// const templeF = textureLoader.load('/textures/temple/new/f.jpg')
// const templeG = textureLoader.load('/textures/temple/new/g.jpg')
// const templeH = textureLoader.load('/textures/temple/new/h.jpg')
// const templeI = textureLoader.load('/textures/temple/new/i.jpg')
// const templeJ = textureLoader.load('/textures/temple/new/j.jpg')
// const templeK = textureLoader.load('/textures/temple/new/k.jpg')
// const templeL = textureLoader.load('/textures/temple/new/l.jpg')
// const templeM = textureLoader.load('/textures/temple/new/m.jpg')

// const templeTextureList = [
//     templeA,
//     templeB,
//     templeC,
//     templeD,
//     templeE,
//     templeF,
//     templeG,
//     templeH,
//     templeI,
//     templeJ,
//     templeK,
//     templeL,
//     templeM,
// ]


// const random = Math.floor(Math.random() * templeTextureList.length)
// // console.log(templeTextureList[random])






const tileColorTexture = textureLoader.load('/textures/floor/tile.jpg')
const tileAmbientOcclusionTexture = textureLoader.load('/textures/floor/alpha.jpg')
const tileRoughnessTexture = textureLoader.load('/textures/floor/invert.jpg')

tileColorTexture.repeat.set(2, 2)
tileAmbientOcclusionTexture.repeat.set(32, 32)
tileRoughnessTexture.repeat.set(32, 32)

tileColorTexture.wrapS = THREE.RepeatWrapping
tileAmbientOcclusionTexture.wrapS = THREE.RepeatWrapping
tileRoughnessTexture.wrapS = THREE.RepeatWrapping

tileColorTexture.wrapT = THREE.RepeatWrapping
tileAmbientOcclusionTexture.wrapT = THREE.RepeatWrapping
tileRoughnessTexture.wrapT = THREE.RepeatWrapping



// grass
const grassColorTexture = textureLoader.load('/textures/grass/color.jpg')
const grassAmbientOcclusionTexture = textureLoader.load('/textures/grass/ambientOcclusion.jpg')
const grassNormalTexture = textureLoader.load('/textures/grass/normal.jpg')
const grassRoughnessTexture = textureLoader.load('/textures/grass/roughness.jpg')

grassColorTexture.repeat.set(300, 300)
grassAmbientOcclusionTexture.repeat.set(300, 300)
grassNormalTexture.repeat.set(300, 300)
grassRoughnessTexture.repeat.set(300, 300)

grassColorTexture.wrapS = THREE.RepeatWrapping
grassAmbientOcclusionTexture.wrapS = THREE.RepeatWrapping
grassNormalTexture.wrapS = THREE.RepeatWrapping
grassRoughnessTexture.wrapS = THREE.RepeatWrapping

grassColorTexture.wrapT = THREE.RepeatWrapping
grassAmbientOcclusionTexture.wrapT = THREE.RepeatWrapping
grassNormalTexture.wrapT = THREE.RepeatWrapping
grassRoughnessTexture.wrapT = THREE.RepeatWrapping




/**
 * Sky Dome
 */
const skyGeo = new THREE.SphereGeometry(10000, 50, 50) 


// const skyTexture = textureLoader.load( "/textures/sky/cosmos3.jpg" )



var skyMaterial = new THREE.MeshPhongMaterial({ 
    map: skyTexture,
    fog: true,
});


var sky = new THREE.Mesh(skyGeo, skyMaterial);
sky.material.side = THREE.BackSide;
sky.rotation.x += 1
scene.add(sky);













/**
 * House
 */
// Group 1
const house = new THREE.Group()
// scene.add(house)

// Walls
const walls = new THREE.Mesh(
    new THREE.BoxGeometry(4, 2.5, 4),
    new THREE.MeshStandardMaterial({
        map: bricksColorTexture,
        aoMap: bricksAmbientOcclusionTexture,
        normalMap: bricksNormalTexture,
        roughnessMap: bricksRoughnessTexture,
    })
)
walls.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(walls.geometry.attributes.uv.array, 2))
walls.position.y = 2.5 / 2
house.add(walls)

// Roof
const roof = new THREE.Mesh(
    new THREE.ConeGeometry(3.5, 1, 4),
    new THREE.MeshStandardMaterial({ color: '#b35f45' })
)
roof.position.y = 2.5 + 0.5
roof.rotation.y = Math.PI * 0.25
house.add(roof)



// Group 2
// -------------------------------------------------------- Houses Loop ------------------------------------------------
const houses_pos =  [-10, -9,  -8, -7, -6, -4, 4, 6, 8, 10]

// // for(let x=-10; x<10; x++){
// //     for(let z=-10; z<10; z++){
// for(let x=0; x<houses_pos.length; x++){
//     for(let z=0; z<houses_pos.length; z++){
//         const durbar = new THREE.Group()

//         const random = Math.floor(Math.random() * templeTextureList.length)
//         const bricksColorTexture = (templeTextureList[random])
        

//         // Durbar Walls
//         // const durbarwalls = walls.clone()
//         const durbarwalls = new THREE.Mesh(
//             new THREE.BoxGeometry(4, 2.5, 4),
//             new THREE.MeshStandardMaterial({
//                 map: bricksColorTexture,
//                 // aoMap: bricksAmbientOcclusionTexture,
//                 // normalMap: bricksNormalTexture,
//                 // roughnessMap: bricksRoughnessTexture,
//             })
//         )
//         durbarwalls.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(durbarwalls.geometry.attributes.uv.array, 2))
//         durbarwalls.position.y = 2.5 / 2
//         durbar.add(durbarwalls)

//         const durbarwalls2 = new THREE.Mesh(
//             new THREE.BoxGeometry(4, 2.5, 4),
//             new THREE.MeshStandardMaterial({
//                 map: bricksColorTexture,
//                 aoMap: bricksAmbientOcclusionTexture,
//                 normalMap: bricksNormalTexture,
//                 roughnessMap: bricksRoughnessTexture,
//                 roughness:10
//             })
//         )
        
//         const randX = Math.random()
//         const randY = Math.random()
//         const randZ = Math.random()

//         durbarwalls2.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(durbarwalls2.geometry.attributes.uv.array, 2))
//         durbarwalls2.position.y = 4.2
//         durbarwalls2.scale.x = 0.7
//         durbarwalls2.scale.y = 0.7
//         durbarwalls2.scale.z = 0.7
        
//         durbar.scale.x = randX * 4 + 1 
//         durbar.scale.y = randY * 4 + 1 
//         durbar.scale.z = randZ * 4 + 1
//         durbar.add(durbarwalls2)

//         // Durbar Roof
//         const durbarRoof = new THREE.Mesh(
//             new THREE.ConeGeometry(3.5, 2, 4,),
//             new THREE.MeshStandardMaterial({ color: '#b35f45' , roughness:5})
//         )
//         durbarRoof.position.y = 2.5 + 1
//         durbarRoof.rotation.y = Math.PI * 0.25
//         durbar.add(durbarRoof)
        
//         durbar.position.x = houses_pos[x] * 150 + Math.random() * 10
//         durbar.position.z = houses_pos[z] * 150 + Math.random() * 10
//         // durbar.position.x = x * 100 + Math.random() * 10
//         // durbar.position.z = z * 100 + Math.random() * 10
//         // console.log(x, z)

//         // Durbar Roof 2
//         const durbarRoof2 = new THREE.Mesh(
//             new THREE.ConeGeometry(3.5, 2, 4,),
//             new THREE.MeshStandardMaterial({ color: '#b35f45' , roughness:5})
//         )
//         durbarRoof2.position.y = 5.6
//         durbarRoof2.scale.x = 0.8
//         durbarRoof2.scale.y = 0.8
//         durbarRoof2.scale.z = 0.8
//         durbarRoof2.rotation.y = Math.PI * 0.25
//         durbar.add(durbarRoof2)
//         durbar.scale.y =  15 + Math.random() * 15
//         durbar.scale.x =  10 + Math.random() * 15
//         durbar.scale.z =  10 + Math.random() * 15
//         // scene.add(durbar)

//     }
// }



// Floor
tileColorTexture.repeat.x = 100
tileColorTexture.repeat.y = 100
tileColorTexture.wrapS = THREE.MirroredRepeatWrapping
tileColorTexture.wrapT = THREE.MirroredRepeatWrapping

tileAmbientOcclusionTexture.repeat.x = 100
tileAmbientOcclusionTexture.repeat.y = 100
tileAmbientOcclusionTexture.wrapS = THREE.MirroredRepeatWrapping
tileAmbientOcclusionTexture.wrapT = THREE.MirroredRepeatWrapping

tileRoughnessTexture.repeat.x = 100
tileRoughnessTexture.repeat.y = 100
tileRoughnessTexture.wrapS = THREE.MirroredRepeatWrapping
tileRoughnessTexture.wrapT = THREE.MirroredRepeatWrapping



const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(5000, 5000),
    new THREE.MeshStandardMaterial({
        map: tileColorTexture,
        aoMap: tileAmbientOcclusionTexture,
        // normalMap: tileNormalTexture,
        roughnessMap: tileRoughnessTexture,    
    })
)

// const floor = new THREE.Mesh(
//     new THREE.PlaneGeometry(5000, 5000),
//     new THREE.MeshStandardMaterial({
//         map: grassColorTexture,
//         aoMap: grassAmbientOcclusionTexture,
//         normalMap: grassNormalTexture,
//         roughnessMap: grassRoughnessTexture,    
//     })
// )
floor.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(floor.geometry.attributes.uv.array, 2))
floor.rotation.x = - Math.PI * 0.5
floor.position.y = 0
scene.add(floor)


/**
 * Nayatpola Temple
 */

const nayatpolGroup = new THREE.Group()
scene.add(nayatpolGroup)

const nayatpolBaseGeometry = new THREE.BoxGeometry(10, 1, 10)
const nayatpolBaseMaterial = new THREE.MeshBasicMaterial({color: 0xbbbbbb, map:nayatpolBaseTexture  })
const nayatpolBase = new THREE.Mesh(nayatpolBaseGeometry, nayatpolBaseMaterial)
nayatpolBase.position.y = 1

const nayatpolBase2 =  nayatpolBase.clone(true)
nayatpolBase2.scale.set(0.9, 1, 0.9)
nayatpolBase2.position.y = 1

const nayatpolBase3 =  nayatpolBase.clone(true)
nayatpolBase3.scale.set(0.8, 1, 0.8)
nayatpolBase3.position.y = 2

const nayatpolBase4 =  nayatpolBase.clone(true)
nayatpolBase4.scale.set(0.7, 1, 0.7)
nayatpolBase4.position.y = 3

const nayatpolBase5 =  nayatpolBase.clone(true)
nayatpolBase5.scale.set(0.6, 1, 0.6)
nayatpolBase5.position.y = 4

nayatpolGroup.add(nayatpolBase, nayatpolBase2, nayatpolBase3, nayatpolBase4, nayatpolBase5)


const nayatpolRoomGeometry = new THREE.BoxGeometry(4, 4, 4)
const nayatpolRoomMaterial = new THREE.MeshBasicMaterial({color: 0xbbbbbb, map:nayatpolTexture})
const nayatpolRoomUpMaterial = new THREE.MeshBasicMaterial({color: 0xbbbbbb, map:templeTexture})
const nayatpolRoom = new THREE.Mesh(nayatpolRoomGeometry, nayatpolRoomUpMaterial)
const nayatpolRoom2 = new THREE.Mesh(nayatpolRoomGeometry, nayatpolRoomUpMaterial)
const nayatpolRoom3 = new THREE.Mesh(nayatpolRoomGeometry, nayatpolRoomUpMaterial)
const nayatpolRoom4 = new THREE.Mesh(nayatpolRoomGeometry, nayatpolRoomUpMaterial)
const nayatpolRoom5 = new THREE.Mesh(nayatpolRoomGeometry, nayatpolRoomUpMaterial)

nayatpolRoom.position.y = 5
nayatpolRoom2.position.y = 8
nayatpolRoom2.scale.set(0.7, 0.7, 0.7)

nayatpolRoom3.position.y = 10
nayatpolRoom3.scale.set(0.5, 0.5, 0.5)

nayatpolRoom4.position.y = 12
nayatpolRoom4.scale.set(0.3, 0.3, 0.3)

nayatpolRoom5.position.y = 13.5
nayatpolRoom5.scale.set(0.1, 0.1, 0.1)

nayatpolGroup.add(nayatpolRoom, nayatpolRoom2, nayatpolRoom3, nayatpolRoom4, nayatpolRoom5)

const nayatpolaRoof = roof.clone()
nayatpolaRoof.material.color.set(0xffaa66)
nayatpolaRoof.scale.set(1.3, 2, 1.3)
nayatpolaRoof.position.y = 8



const nayatpolaRoof2 = nayatpolaRoof.clone()
nayatpolaRoof2.scale.set(1,  1.5, 1)
nayatpolaRoof2.position.y = 10

const nayatpolaRoof3 = nayatpolaRoof.clone()
nayatpolaRoof3.scale.set(0.7,  1.2, 0.7)
nayatpolaRoof3.position.y = 11.5

const nayatpolaRoof4 = nayatpolaRoof.clone()
nayatpolaRoof4.scale.set(0.5,  1, 0.5)
nayatpolaRoof4.position.y = 13

const nayatpolaRoof5 = nayatpolaRoof.clone()
nayatpolaRoof5.scale.set(0.2,  0.3, 0.2)
nayatpolaRoof5.position.y = 13.8

nayatpolGroup.add(nayatpolaRoof, nayatpolaRoof2, nayatpolaRoof3, nayatpolaRoof4, nayatpolaRoof5 )
nayatpolGroup.scale.set(70, 70, 70)
nayatpolGroup.position.set(-1000, -35, 0)















/**
 * Dharahara
 */
const dharaharaGroup = new THREE.Group()


const dharahara = new THREE.Mesh(
    new THREE.CylinderGeometry(10, 15, 200, 40, 1),
    new THREE.MeshBasicMaterial({ map:dharaharaTexture })
)


dharahara.position.set(20, 100, 500)
dharaharaGroup.add(dharahara)
dharaharaGroup.position.z -= 40


const hoodpoints = [];
for ( let i = 0; i < 10; i ++ ) {
	hoodpoints.push( new THREE.Vector2( Math.sin( i * 0.2 ) * 10 + 5, ( i - 5 ) * 2 ) );
}
const geometry = new THREE.LatheGeometry( hoodpoints );
const material = new THREE.MeshBasicMaterial( { color: 0xeeeeee } );
const lathe = new THREE.Mesh( geometry, material );
lathe.position.set(20, 200, 500)
lathe.rotation.x = Math.PI
lathe.scale.x = 0.7
lathe.scale.z = 0.7
dharaharaGroup.add( lathe );


const gajuri = new THREE.Mesh(
    new THREE.ConeGeometry( 1, 20, 32 ),
    new THREE.MeshBasicMaterial( {color: 0xffff00} )
)
gajuri.position.set(20, 220, 500)
dharaharaGroup.add(gajuri)
dharaharaGroup.castShadow = true
dharaharaGroup.receiveShadow = true

// dharaharaGroup.scale.x += 1
dharaharaGroup.scale.y = 3.5
dharaharaGroup.scale.x = 3.5
dharaharaGroup.scale.z = 3.5
dharaharaGroup.position.z += 1300
dharaharaGroup.position.x += 800
dharaharaGroup.rotation.y += Math.PI

scene.add(dharaharaGroup)



/**
 * Bouddhanath
 */
const bouddhanathGroup = new THREE.Group()



const bouddhaBase = new THREE.Mesh(
    new THREE.BoxGeometry(10, 1, 10),
    new THREE.MeshBasicMaterial({color:0x888888})
)

const bouddhaDome = new THREE.Mesh(
    new THREE.SphereGeometry(3, 64, 32, 0, 2*Math.PI, 0, 1.6),
    new THREE.MeshBasicMaterial({ map: bouddhaDomeTexture })
)
bouddhaDome.position.y += 0.4

const bouddhaEye = new THREE.Mesh(
    new THREE.BoxGeometry(1.5, 1, 1.5),
    new THREE.MeshBasicMaterial({ map: bouddhaEyeTexture })
)
bouddhaEye.position.y += 3.5

const bouddhaPyramid = new THREE.Mesh(
    new THREE.ConeGeometry(1, 3, 4, 1, true, 3.14/4),
    new THREE.MeshBasicMaterial({ map: bouddhaPyramidTexture })
)
bouddhaPyramid.position.y += 5

bouddhanathGroup.add(bouddhaBase, bouddhaDome, bouddhaEye, bouddhaPyramid)
bouddhanathGroup.scale.set(50, 50, 50)
bouddhanathGroup.position.set(0, 1, -800)
scene.add(bouddhanathGroup)


/**
 * Low Poly Tree
 */
const treeGroup = new THREE.Group()

const treeLeaves = new THREE.Mesh(
    new THREE.IcosahedronGeometry(20, 0),
    new THREE.MeshBasicMaterial({ map: leavesTexture})
)
treeLeaves.position.y = 40
treeLeaves.rotation.x += 0.5

const treeStem = new THREE.Mesh(
    new THREE.CylinderGeometry(4, 4, 45, 10, 10, false, 0, 2*Math.PI),
    new THREE.MeshBasicMaterial({ map: stemTexture })
)
treeStem.position.y = 10

treeGroup.add(treeStem, treeLeaves)


for (let i=-5; i<5; i++){
    for (let j=-5; j<5; j++){
        const tree = treeGroup.clone()
        tree.position.set(i* 400 +140, 10, j*400 + 140)
        tree.scale.y = 1.3 + Math.random() * 2
        
        scene.add(tree)
    }
}
// scene.add(treeGroup)


/**
 * Status
 */
// KaalBhairav

const KaalBhairav = new THREE.Mesh(
    new THREE.BoxGeometry(30, 50, 5),
    new THREE.MeshBasicMaterial({ map: KaalBhairavTexture })
)
KaalBhairav.position.set(-150, 50, 350)
KaalBhairav.scale.set(3, 3, 3)
scene.add(KaalBhairav)

var controllerIndex = null
window.addEventListener("gamepadconnected", (event) => {
    controllerIndex = event.gamepad.index;
    console.log(controllerIndex);
});





/**Sajha Yatayat */




/**
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight('#ddbbdd', 1)
gui.add(ambientLight, 'intensity').min(0).max(1).step(0.001)
scene.add(ambientLight)

// Directional light
const moonLight = new THREE.DirectionalLight('#bbbbbb', 0.5)
moonLight.position.set(40, 50, 50)
gui.add(moonLight, 'intensity').min(0).max(1).step(0.001)
gui.add(moonLight.position, 'x').min(- 5).max(5).step(0.001)
gui.add(moonLight.position, 'y').min(- 5).max(5).step(0.001)
gui.add(moonLight.position, 'z').min(- 5).max(5).step(0.001)
moonLight.castShadow = true
scene.add(moonLight)

// Door Light
const doorLight = new THREE.PointLight('#dddddd', 5, 1000)
doorLight.position.set(0, 1000, -800)
// scene.add(doorLight)




/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(70, sizes.width / sizes.height, 0.001, 100000)
camera.position.x = 500
camera.position.y = 25
camera.position.z = 880
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
// controls.autoRotate = true


const pControls = new PointerLockControls(camera, canvas)
const playButton = document.getElementById('menuPanel')
playButton.onclick = () =>
{   
    pControls.lock()
    playButton.style.display = 'none'
}


pControls.addEventListener('unlock', () => {
    playButton.style.display = 'block'
})
// pControls.lock()
// playButton.style.display = 'none'


if (sizes.width<1000)
{
    // pControls.lock()
    playButton.style.display = 'none'
}


// Key down events
const keyMap = {}
const onDocumentKey = (e) => {
    keyMap[e.code] = e.type === 'keydown'
}
document.addEventListener('keydown', onDocumentKey, false)
document.addEventListener('keyup', onDocumentKey, false)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    logarithmicDepthBuffer: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor('#262837')
document.body.appendChild(canvas)


/**
 * Shadows
 */
// renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

moonLight.castShadow = true
doorLight.castShadow = true

walls.castShadow = true

floor.receiveShadow = true

doorLight.shadow.mapSize.width = 256
doorLight.shadow.mapSize.height = 256
doorLight.shadow.camera.far = 7



// Add variables to track button states
let forwardPressed = false;
let backwardPressed = false;
let leftPressed = false;
let rightPressed = false;
let upPressed = false;
let downPressed = false;

// Add event listeners for button clicks
document.getElementById('moveForward').addEventListener('touchstart', function() {
    forwardPressed = true;
});
document.getElementById('moveForward').addEventListener('touchend', function() {
    forwardPressed = false;
});

document.getElementById('moveBackward').addEventListener('touchstart', function() {
    backwardPressed = true;
});
document.getElementById('moveBackward').addEventListener('touchend', function() {
    backwardPressed = false;
});

document.getElementById('moveLeft').addEventListener('touchstart', function() {
    leftPressed = true;
});
document.getElementById('moveLeft').addEventListener('touchend', function() {
    leftPressed = false;
});

document.getElementById('moveRight').addEventListener('touchstart', function() {
    rightPressed = true;
});
document.getElementById('moveRight').addEventListener('touchend', function() {
    rightPressed = false;
});

document.getElementById('moveUp').addEventListener('touchstart', function() {
    upPressed = true;
});
document.getElementById('moveUp').addEventListener('touchend', function() {
    upPressed = false;
});

document.getElementById('moveDown').addEventListener('touchstart', function() {
    downPressed = true;
});
document.getElementById('moveDown').addEventListener('touchend', function() {
    downPressed = false;
});



// double-click event listener for fullscreen
window.addEventListener('dblclick', () => {
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement
    if(!fullscreenElement) 
    {
        if(canvas.requestFullscreen) {
            canvas.requestFullscreen()
        }
        else if(canvas.webkitRequestFullscreen) {
            canvas.webkitFullscreen()
        }
    }
    else 
    {
        if(document.exitFullscreen) {
            document.exitFullscreen()
        }
        else if(document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        }
    }
})


// Joystick


const joystickContainer = document.getElementById('joystickContainer');
const joystick = document.getElementById('joystick');

// Variables to store joystick position
let joystickCenterX = 60;
let joystickCenterY = 60;
let touchStartX = 0;
let touchStartY = 0;

// Function to update joystick position
const updateJoystickPosition = (x, y) => {
    let deltaX = x - joystickCenterX;
    let deltaY = y - joystickCenterY;
    let distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    if (distance > 40) {
        let angle = Math.atan2(deltaY, deltaX);
        joystick.style.left = (joystickCenterX + Math.cos(angle) * 40) + 'px';
        joystick.style.top = (joystickCenterY + Math.sin(angle) * 40) + 'px';
    } else {
        joystick.style.left = x + 'px';
        joystick.style.top = y + 'px';
    }
};

// Touch start event listener
joystickContainer.addEventListener('touchstart', (event) => {
    const touch = event.touches[0];
    touchStartX = touch.clientX;
    touchStartY = touch.clientY;
    updateJoystickPosition(touchStartX, touchStartY);
});

// Touch move event listener
joystickContainer.addEventListener('touchmove', (event) => {
    event.preventDefault(); // Prevent scrolling
    const touch = event.touches[0];
    updateJoystickPosition(touch.clientX, touch.clientY);
});

// Touch end event listener
joystickContainer.addEventListener('touchend', () => {
    joystick.style.left = joystickCenterX + 'px';
    joystick.style.top = joystickCenterY + 'px';
});





// Audio Effects
// // create an AudioListener and add it to the camera
// const listener = new THREE.AudioListener();
// camera.add( listener );

// // create a global audio source
// const sound = new THREE.Audio( listener );

// // load a sound and set it as the Audio object's buffer
// const audioLoader = new THREE.AudioLoader();
// audioLoader.load( 'audio/Mahendra_Highway_12.m4a', function( buffer ) {
// 	sound.setBuffer( buffer );
// 	sound.setLoop( true );
// 	sound.setVolume( 0.5 );
// 	sound.play();
// });


// create an AudioListener and add it to the camera
const listener = new THREE.AudioListener();
camera.add( listener );

// create the PositionalAudio object (passing in the listener)
const sound = new THREE.PositionalAudio( listener );
sound.loop = true;



// load a sound and set it as the PositionalAudio object's buffer
const audioLoader = new THREE.AudioLoader();
audioLoader.load( 'audio/evening-birds-singing-in-spring-background-sounds-of-nature-146388.mp3', function( buffer ) {
	sound.setBuffer( buffer );
	sound.setRefDistance( 20 );
    sound.setVolume( 1 );
	sound.play();
});

// create an object for the sound to play from
const sphere = new THREE.SphereGeometry( 20, 32, 16 );
const audioMaterial = new THREE.MeshPhongMaterial( { color: 0xff2200 } );
const mesh = new THREE.Mesh( sphere, audioMaterial );
mesh.visible = false
scene.add( mesh );

// finally add the sound to the mesh
mesh.add( sound );


// Om mani padme hum
const sound2 = new THREE.PositionalAudio( listener );
sound2.loop = true
const audioLoader2 = new THREE.AudioLoader();
audioLoader2.load( 'audio/om_mani_padme_hum.mp3', function( buffer ) {
	sound2.setBuffer( buffer );
    sound2.setVolume( 1 );
	sound2.setRefDistance( 10 );
	sound2.play();
});
bouddhaDome.add(sound2)

/**
 * Glb loader
//  */
const glbLoader = new GLTFLoader()

// glbLoader.load('/textures/models/plant.glb', function ( gltf ) {
//     const gamala = gltf.scene
//     const gamala2 = gamala.clone()

//     gamala.scale.set(20, 20, 20)
//     gamala2.scale.set(20, 20, 20)

//     gamala.position.set(30, 0, 160)
//     gamala2.position.set(70, 0, 160)
    // scene.add(gamala, gamala2)
// })

// Buddha statue from Newroad
glbLoader.load('/textures/models/newroad_statue.glb', function ( gltf ) {
    const newroad_statue = gltf.scene
    // plant.castShadow = true
    // plant.receiveShadow = true
    newroad_statue.scale.set(80, 80, 80)
    newroad_statue.rotation.z += 0.15
    newroad_statue.rotation.x += 0.09
    newroad_statue.rotation.y -= (2 * Math.PI) / 10
    newroad_statue.position.set(0, -7, 440)
    
    const newroad_statue2 = newroad_statue.clone()
    newroad_statue2.position.set(-440, -7, 0)

    const newroad_statue3 = newroad_statue.clone()
    newroad_statue3.position.set(440, -7, 0)

    const newroad_statue4 = newroad_statue.clone()
    newroad_statue4.position.set(0, -7, -440)

    scene.add(newroad_statue, newroad_statue2, newroad_statue3, newroad_statue4) 
})


// Shivalinga statue 
// glbLoader.load('/textures/models/new/statue7.glb', function ( gltf ) {
//     const newroad_statue = gltf.scene
//     // plant.castShadow = true
//     // plant.receiveShadow = true
//     newroad_statue.scale.set(80, 80, 80)
//     newroad_statue.rotation.z += 0.15
//     newroad_statue.rotation.x += 0.09
//     newroad_statue.rotation.y -= (2 * Math.PI) / 10
//     newroad_statue.position.set(300, -35, 440)
    
//     const newroad_statue2 = newroad_statue.clone()
//     newroad_statue2.position.set(-440, -7, 0)

//     const newroad_statue3 = newroad_statue.clone()
//     newroad_statue3.position.set(440, -7, 0)

//     const newroad_statue4 = newroad_statue.clone()
//     newroad_statue4.position.set(0, -7, -440)

//     scene.add(newroad_statue, newroad_statue2, newroad_statue3, newroad_statue4) 
// })



// glbLoader.load('/textures/models/ktm2.glb', function ( gltf ) {
//     const plant = gltf.scene

//     plant.scale.set(20, 20, 20)

//     plant.position.set(30, 0, 250)
//     scene.add(plant)
// })


// Garuda from Basantapur
glbLoader.load('/textures/models/garuda.glb', function ( gltf ) {
    const garuda = gltf.scene
    garuda.position.y -= 100
    garuda.rotation.y -= 0.3
    garuda.rotation.z -= 0
    garuda.scale.set(600, 600, 600)

    const garuda2 = garuda.clone()
    const garuda3 = garuda.clone()
    const garuda4 = garuda.clone()

    garuda.position.set(1700, -10, 0)
    garuda2.position.set(-1700, -10, 0)
    garuda3.position.set(0, -10, 1700)
    // garuda4.position.set(0, 0, -1700)

    garuda2.rotation.y += Math.PI 
    garuda3.rotation.y += Math.PI + Math.PI/2
    garuda4.rotation.y += Math.PI /2

    scene.add(garuda, garuda2, garuda3)
    // scene.add(garuda, garuda2, garuda3, garuda4)
})

// glbLoader.load('/textures/models/deep_basantapur.glb', function ( gltf ) {
//     const plant = gltf.scene

//     plant.scale.set(20, 20, 20)

//     plant.position.set(30, 0, 300)
//     // scene.add(plant)
// })


// glbLoader.load('/textures/models/bhaktapur_statue_isolated.glb', function ( gltf ) {
//     const plant = gltf.scene

//     plant.scale.set(20, 20, 20)
//     plant.rotation.y += Math.PI

//     plant.position.set(30, 0, 350)
//     // scene.add(plant)
// })

// glbLoader.load('/textures/models/bhaktapur_another.glb', function ( gltf ) {
//     const plant = gltf.scene

//     plant.scale.set(20, 20, 20)
//     plant.rotation.y += Math.PI

//     plant.position.set(30, 0, 400)
//     // scene.add(plant)
// })

// Tripureshor
glbLoader.load('/textures/models/tripureswor.glb', function ( gltf ) {
    const tripuresworStatue = gltf.scene

    tripuresworStatue.scale.set(400, 400, 400)
    tripuresworStatue.rotation.y += 0
    tripuresworStatue.rotation.z += 0.0
    tripuresworStatue.rotation.x += 0
    tripuresworStatue.position.set(0, -6 , 1000)
    scene.add(tripuresworStatue)
})


// Basantapur Durbar
glbLoader.load('/textures/basantapur/basantapurLong3.glb', function ( gltf ) {
    const whiteDurbar = gltf.scene

    whiteDurbar.scale.set(1500, 1500, 1500)
    whiteDurbar.rotation.y -= Math.PI/3

    whiteDurbar.position.set(730, 0, -2000)
    scene.add(whiteDurbar)
})  


// Basantapur Durbar Houses
glbLoader.load('/textures/basantapur/basantapurHouses.glb', function ( gltf ) {
    const basantapurHouses = gltf.scene

    basantapurHouses.scale.set(800, 800, 800)
    basantapurHouses.rotation.y -= Math.PI/2

    basantapurHouses.position.set(2150, -40, -200)
    scene.add(basantapurHouses)
})  

// // Bhaktapur Durbar Houses
// glbLoader.load('/textures/basantapur/bhaktapurHouses.glb', function ( gltf ) {
//     const basantapurHouses = gltf.scene

//     basantapurHouses.scale.set(800, 800, 800)
//     basantapurHouses.rotation.y -= 0

//     basantapurHouses.position.set(2150, 1000, -1000)
//     scene.add(basantapurHouses)
// })  


// Vortex Lab Cube
let cube = false
glbLoader.load('/textures/models/vortexLab/VortexLabCube.glb', function ( gltf ) {
    const vortexLabCube = gltf.scene

    vortexLabCube.scale.set(22, 22, 22)
    vortexLabCube.rotation.y -= 0
    cube = vortexLabCube.clone()
    cube.position.set(0, 50, 0)
    scene.add(cube)

})


// Valley Hills
glbLoader.load('/textures/models/valley/ktmValley.glb', function ( gltf ) {
    const hills = gltf.scene

    hills.scale.set(2000, 2000, 2000)    
    hills.position.set(0, -1, 0)
    scene.add(hills)

})



// // Basantapur Durbar
// glbLoader.load('/textures/tree/tree.glb', function ( gltf ) {
//     const whiteDurbar = gltf.scene

//     whiteDurbar.scale.set(10, 10, 10)
//     // whiteDurbar.rotation.y += Math.PI

//     whiteDurbar.position.set(0, 0, 300)
//     // scene.add(whiteDurbar)
// })  


// // NewRoad Statue
// glbLoader.load('/textures/basantapur/newRoadStatue.glb', function ( gltf ) {
//     const newroadBuddha = gltf.scene

//     newroadBuddha.scale.set(100, 100, 100)
//     // newroadBuddha.rotation.y += Math.PI

//     newroadBuddha.position.set(-300, 0, 0)
//     // scene.add(newroadBuddha)
// })  



// Basantapur small temple
glbLoader.load('/textures/basantapur/templeSmall.glb', function ( gltf ) {
    const templeSmall = gltf.scene
    templeSmall.scale.set(600, 600, 600)
    // whiteDurbar.rotation.y += Math.PI
    const templesGroup = new THREE.Group() 

    for (let i=0; i<houses_pos.length; i++) {
        for (let j=0; j<houses_pos.length; j++) {
        const temp = templeSmall.clone()
        const rand = Math.random()
        temp.scale.set(100 + rand*150 , 100 + rand*150, 100+ rand*150)
        temp.position.set(houses_pos[i] * 150 + 1000 + Math.random() * 500, -5, houses_pos[j]*150 + 1000 + Math.random() * 500)
        // temp.scale.x += 100 + Math.random() * 3
        templesGroup.add(temp)
    }}

    templeSmall.position.set(0, -10, 1000)
    templesGroup.position.x -= 1000
    templesGroup.position.z -= 1000
    scene.add(templesGroup)
}) 




// // Basantapur temples
// glbLoader.load('/textures/basantapur/temples.glb', function ( gltf ) {
//     const temples = gltf.scene
//     temples.rotation.y += Math.PI

//     temples.scale.set(500, 500, 500)
//     // whiteDurbar.rotation.y += Math.PI

//     temples.position.set(-1200, -30, 1200)
//     scene.add(temples)
// })


// Durbar Lion
glbLoader.load('/textures/basantapur/durbarLion.glb', function ( gltf ) {
    const durbarLion = gltf.scene
    durbarLion.scale.set(120, 120, 120)

    const durbarLion2 = durbarLion.clone()
    const durbarLion3 = durbarLion.clone()
    const durbarLion4 = durbarLion.clone()

    durbarLion.position.set(0, -12, 200)
    durbarLion2.position.set(0, -12, -200)
    durbarLion3.position.set(200, -12, 0)
    durbarLion4.position.set(-200, -12, 0)

    durbarLion2.rotation.y += Math.PI
    durbarLion3.rotation.y += Math.PI/2
    durbarLion4.rotation.y -= Math.PI/2

    scene.add(durbarLion, durbarLion2, durbarLion3, durbarLion4)
})


// glbLoader.load('/textures/models/new/statue8.glb', function ( gltf ) {
//     const garuda = gltf.scene
//     garuda.rotation.y -= 0.3 + Math.PI
//     garuda.scale.set(200, 200,200)

//     const garuda2 = garuda.clone()
//     const garuda3 = garuda.clone()
//     const garuda4 = garuda.clone()

//     garuda.position.set(800, -100, 0)
//     garuda2.position.set(-800, -100, 0)
//     garuda3.position.set(0, -100, 800)
//     garuda4.position.set(0, -100, -800)

//     garuda2.rotation.y += Math.PI 
//     garuda3.rotation.y += Math.PI + Math.PI/2
//     garuda4.rotation.y += Math.PI /2

//     // scene.add(garuda, garuda2, garuda3, garuda4)
// })


// // Car Model
// let car = true
// // glbLoader.load('/textures/models/car/Range_Rover_Sports_2018.glb', function ( gltf ) {
// glbLoader.load('/textures/models/car/whiteCar.glb', function ( gltf ) {

//     car = gltf.scene
//     // car.rotation.y -= 0.3 + Math.PI
//     car.position.set(200, 11, 50)
//     car.scale.set(10, 10, 10)


//     // const garuda2 = garuda.clone()
//     // const garuda3 = garuda.clone()
//     // const garuda4 = garuda.clone()

//     // garuda.position.set(800, -100, 0)
//     // garuda2.position.set(-800, -100, 0)
//     // garuda3.position.set(0, -100, 800)
//     // garuda4.position.set(0, -100, -800)

//     // garuda2.rotation.y += Math.PI 
//     // garuda3.rotation.y += Math.PI + Math.PI/2
//     // garuda4.rotation.y += Math.PI /2
//     // console.log(car)
//     scene.add(car)


    
// })




/**
 * Animate
 */
const moveSpeed = 4
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls

    if (sizes.width < 1000){
        controls.update()
    }

    // controls.update()    
    if (keyMap['KeyW'] || keyMap['ArrowUp']) {
        pControls.moveForward(moveSpeed)
    }
    if (keyMap['KeyS'] || keyMap['ArrowDown']) {
        pControls.moveForward(-moveSpeed)
    }
    if (keyMap['KeyA'] || keyMap['ArrowLeft']) {
        pControls.moveRight(-moveSpeed)
    }
    if (keyMap['KeyD'] || keyMap['ArrowRight']) {
        pControls.moveRight(moveSpeed)
    }
    if (keyMap['KeyE']) {
        camera.position.y += moveSpeed
    }
    if (keyMap['KeyQ']) {
        camera.position.y -= moveSpeed
    }

    // if (keyMap['KeyF']) {
    //     car.position.z += moveSpeed
    //     // camera.position.x = car.position.x
    //     // camera.position.z = car.position.z
    //     // camera.position.y = car.position.z - 10
    // }

    // if (keyMap['KeyB']) {
    //     car.position.z -= moveSpeed
    //     // camera.position.x = car.position.x
    //     // camera.position.z = car.position.z
    //     // camera.position.y = car.position.z - 10
    // }

    // Update controls based on button states
    if (forwardPressed) {
        pControls.moveForward(moveSpeed);
        
    }
    if (backwardPressed) {
        pControls.moveForward(-moveSpeed);
    }
    if (leftPressed) {
        pControls.moveRight(-moveSpeed);
    }
    if (rightPressed) {
        pControls.moveRight(moveSpeed);
    }
    if (upPressed) {
        camera.position.y += 0.1;
    }
    if (downPressed) {
        camera.position.y -= 0.1;
    }

    



    // Moonlight rotation
    moonLight.position.x = Math.cos(elapsedTime) 
    moonLight.position.y = Math.sin(elapsedTime)
    sky.rotation.y = (elapsedTime) / 100 

    // Cube rotation
    if (cube != false) {
        cube.rotation.x += 0.03
        cube.rotation.y += 0.03
    }



    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}


tick()